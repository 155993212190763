exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actividades-js": () => import("./../../../src/pages/actividades.js" /* webpackChunkName: "component---src-pages-actividades-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-descarga-la-app-js": () => import("./../../../src/pages/descarga-la-app.js" /* webpackChunkName: "component---src-pages-descarga-la-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instalaciones-js": () => import("./../../../src/pages/instalaciones.js" /* webpackChunkName: "component---src-pages-instalaciones-js" */),
  "component---src-pages-politica-de-privacidad-js": () => import("./../../../src/pages/politica-de-privacidad.js" /* webpackChunkName: "component---src-pages-politica-de-privacidad-js" */)
}

